import {
  DateInput,
  DeleteButton,
  Edit,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useRecordContext
} from 'react-admin';
import React from "react";
import {checkDateRange, isRequired, validateJobPositionAttachment} from "../../util/validation";
import {useWatch} from 'react-hook-form';
import {BiLinkExternal} from "react-icons/bi";
import {attachmentFileName} from "../../util/util";
import {CustomRichTextComponent} from "../../util/customRichTextComponent";

const validateForm = (position) => {
  const errors = {};
  if (!position.X10AJOBPOSTITLE) {
    errors.X10AJOBPOSTITLE = 'Required'
  }
  if (!position.X10AJOBPOSDESCRIPTION) {
    errors.X10AJOBPOSDESCRIPTION = 'Required'
  }
  const dateRangeErrorMessage = checkDateRange(
    'Invalid Date range',
    position.X10AJOBPOSSTARTDATE,
    position.X10AJOBPOSENDDATE,
  );
  if (dateRangeErrorMessage) {
    errors.X10AJOBPOSSTARTDATE = dateRangeErrorMessage;
    errors.X10AJOBPOSENDDATE = dateRangeErrorMessage;
  }
  return errors;
}

export const JobPositionsEdit = () => (
  <Edit undoable="false">
    <SimpleForm redirect="list"
                sanitizeEmptyValues
                validate={validateForm}
                mode={"onBlur"}
                reValidateMode={"onBlur"}
                toolbar={<CustomEditDeleteDisableToolbar/>}>
      <div className="two-field-row">
        <TextInput source="X10AJOBPOSTITLE"
                   name="X10AJOBPOSTITLE"
                   validate={isRequired}
                   className="width-50"
                   label="Title"/>
        <SelectInput source="X10AJOBPOSACTIVE" name="X10AJOBPOSACTIVE" label="Active" choices={[
          {option: 'Yes', value: true},
          {option: 'No', value: false}
        ]} optionText="option" optionValue="value"/>
      </div>
      <CustomRichTextComponent required source="X10AJOBPOSDESCRIPTION" label="Description"/>
      <div className="two-field-row">
        <DateInput label="Start Date"
                   validate={isRequired}
                   source="X10AJOBPOSSTARTDATE"
                   name="X10AJOBPOSSTARTDATE"/>
        <DateInput label="End Date"
                   validate={isRequired}
                   source="X10AJOBPOSENDDATE"
                   name="X10AJOBPOSENDDATE"/>
      </div>
      <TextInput source="X10AJOBPOSURL" name="X10AJOBPOSURL" fullWidth label="Url"/>
      <div style={{display: 'flex', width: '100%'}}>
        <TextInput fullWidth source="X10AJOBPOSATTACHMENT" name="X10AJOBPOSATTACHMENT"
                   label="Attachment Link"/>
        <UrlFileActions source="X10AJOBPOSATTACHMENT"/>
      </div>
      <CustomText/>
    </SimpleForm>
  </Edit>
);

const UrlFileActions = props => {
  const attachmentUrl = useWatch({name: 'X10ACNASHAREPOINTFILE'});
  const validUrl = !validateJobPositionAttachment(attachmentUrl).X10AJOBPOSATTACHMENT;
  return <div style={{fontSize: '1.5rem', display: 'flex', padding: '1.2rem', flexGrow: 1}}>
    <a target="_blank" rel="noopener noreferrer" href={attachmentUrl}>
      {validUrl && <BiLinkExternal style={{cursor: 'pointer'}}/>}
    </a>
  </div>
}

const CustomText = props => {
  const attachmentUrl = useWatch({name: 'X10ACNASHAREPOINTFILE'});
  const validUrl = !validateJobPositionAttachment(attachmentUrl).X10AJOBPOSATTACHMENT;
  return validUrl && <p style={{margin: 0, marginTop: '-1rem'}}>{attachmentFileName(attachmentUrl)}</p>
}

const CustomEditDeleteDisableToolbar = props => {
  const record = useRecordContext();
  return <Toolbar {...props} >
    <SaveButton disabled={props.invalid}/>
    <DeleteButton {...props} disabled={record.numberOfApplicants > 0}/>
  </Toolbar>
};