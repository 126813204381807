import React from 'react';
import {
  AutocompleteInput,
  DateInput,
  Edit,
  FormDataConsumer,
  Labeled,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';
import {CustomEditDisableInvalidToolbar} from "../util/customEditToolbar";
import {checkDateRange, isRequired, validateShortDescriptionLength} from "../util/validation";
import {SimpleSelectField} from "../util/simple-select-field";
import {isOpinionPositionSelected} from "../util/util";
import {useFormContext} from "react-hook-form";
import {POSITION} from "../util/enum";
import {CustomRichTextComponent} from "../util/customRichTextComponent";

const validateForm = (article) => {
  const errors = validateShortDescriptionLength(article);
  if (!article.X10ACNWTITLE) {
    errors.X10ACNWTITLE = 'Required';
  }
  if (!article.X10ACNWDATE) {
    errors.X10ACNWDATE = 'Required';
  }
  if (!article.X10ACNWID_X10TCNT) {
    errors.X10ACNWID_X10TCNT = 'Required';
  }
  if (!article.X10ACNWID_X10TCNG) {
    errors.X10ACNWID_X10TCNG = 'Required';
  }
  const dateRangeErrorMessage = checkDateRange(
    'Invalid Date range',
    article.X10ACNWVISIBLEFROM,
    article.X10ACNWVISIBLETO,
  );
  if (dateRangeErrorMessage) {
    errors.X10ACNWVISIBLEFROM = dateRangeErrorMessage;
    errors.X10ACNWVISIBLETO = dateRangeErrorMessage;
  }
  if (isOpinionPositionSelected(article.X10ACNWID_X10LCNP) && !article.X10ACNWID_X10ACNW) {
    errors.X10ACNWID_X10ACNW = 'Required';
  }
  return errors;
}

export const CompanyNewsEdit = () => (
  <Edit undoable="false">
    <SimpleForm
      sanitizeEmptyValues
      redirect="list"
      mode={"onBlur"}
      reValidateMode={"onBlur"}
      validate={validateForm}
      toolbar={<CustomEditDisableInvalidToolbar/>}>
      <FormDataConsumer>
        {({formData}) =>
          !!formData.X10ACNWID_AUTE
            ? <Labeled label="Author">
              <ReferenceField link={false}
                              source="X10ACNWID_AUTE"
                              reference="users">
                <TextField source="C_NOMINATIVO"></TextField>
              </ReferenceField>
            </Labeled>
            : <Labeled label="Author's email">
              <TextField source="X10ACNWCREATOREMAIL"></TextField>
            </Labeled>
        }
      </FormDataConsumer>
      <div className="two-field-row">
        <SelectInput source="X10ACNWITA" name="X10ACNWITA" label="Vis. Italian" choices={[
          {option: 'Yes', value: true},
          {option: 'No', value: false}
        ]} optionText="option" optionValue="value"/>
        <SelectInput source="X10ACNWENG" name="X10ACNWENG" label="Vis. English" choices={[
          {option: 'Yes', value: true},
          {option: 'No', value: false}
        ]} optionText="option" optionValue="value"/>
      </div>
      <div className="two-field-row">
        <TextInput source="X10ACNWTITLE"
                   name="X10ACNWTITLE"
                   validate={isRequired}
                   fullWidth
                   label="Title"/>
        <TextInput source="X10ACNWTITLEENG"
                   name="X10ACNWTITLEENG"
                   fullWidth
                   label="Title English"/>
      </div>
      <DateInput label="Date"
                 validate={isRequired}
                 source="X10ACNWDATE"
                 name="X10ACNWDATE"/>
      <div className="two-field-row">
        <TextInput source="X10ACNWSUBTITLE"
                   name="X10ACNWSUBTITLE"
                   fullWidth
                   label="Subtitle"/>
        <TextInput source="X10ACNWSUBTITLEENG"
                   name="X10ACNWSUBTITLEENG"
                   fullWidth
                   label="Subtitle English"/>
      </div>
      <div className="two-field-row">
        <TextInput source="X10ACNWSHORTDESCR"
                   name="X10ACNWSHORTDESCR"
                   fullWidth
                   label="Short description"/>
        <TextInput source="X10ACNWSHORTDESCRENG"
                   name="X10ACNWSHORTDESCRENG"
                   fullWidth
                   label="Short description English"/>
      </div>
      <ReferenceInput source="X10ACNWID_X10TCNT"
                      name="X10ACNWID_X10TCNT"
                      reference="company-news-type">
        <AutocompleteInput label="Type"
                           validate={isRequired}
                           name="X10ACNWID_X10TCNT"
                           optionText="X10TCNTCODE"/>
      </ReferenceInput>
      <ReferenceInput source="X10ACNWID_X10TCNG"
                      name="X10ACNWID_X10TCNG"
                      reference="company-news-tag">
        <AutocompleteInput label="Tag"
                           validate={isRequired}
                           name="X10ACNWID_X10TCNG"
                           optionText="X10TCNGDESCRIPTION"/>
      </ReferenceInput>
      <CustomPositionSelector/>
      <FormDataConsumer>
        {({formData}) =>
          isOpinionPositionSelected(formData.X10ACNWID_X10LCNP)
          && <ReferenceInput source="X10ACNWID_X10ACNW"
                             name="X10ACNWID_X10ACNW"
                             sort={{field: "X10ACNWDATE", order: 'DESC'}}
                             filter={{X10LCNPDESCRIZIONE: 'normal'}}
                             reference="company-news">
            <AutocompleteInput label="News title"
                               name="X10ACNWID_X10ACNW"
                               validate={isRequired}
                               optionText="X10ACNWTITLE"/>
          </ReferenceInput>
        }
      </FormDataConsumer>
      <SimpleSelectField label="Country"
                         source="X10ACNWID_X10LNAZ"
                         name="X10ACNWID_X10LNAZ"
                         endpoint="countries"
                         sort={{field: 'LNAZDESCRIZIONE'}}
                         params={'X10TGEOCNW_NOT_NULL=true'}
                         parse={val => Number(val)}
                         perPage={1000}
                         optionText="LNAZDESCRIZIONE"/>
      <FormDataConsumer>
        {({formData}) =>
          !!formData.X10ACNWIDNEWS_AUTE
          && <Labeled label="User News">
            <ReferenceField link={false}
                            source="X10ACNWIDNEWS_AUTE"
                            reference="users">
              <TextField source="C_NOMINATIVO"></TextField>
            </ReferenceField>
          </Labeled>
        }
      </FormDataConsumer>
      <TextInput source="X10ACNWNEWSEMAIL"
                 name="X10ACNWNEWSEMAIL"
                 fullWidth
                 label="User Email"/>
      <TextInput source="X10ACNWTEAM"
                 name="X10ACNWTEAM"
                 fullWidth
                 label="Team"/>
      <SelectInput source="X10ACNWPHOTO" name="X10ACNWPHOTO" label="Visualizza Foto" choices={[
        {option: 'Yes', value: '1'},
        {option: 'No', value: '0'}
      ]} optionText="option" optionValue="value"/>
      <SelectInput source="X10ACNWACTIVE" name="X10ACNWACTIVE" label="Active" choices={[
        {option: 'Yes', value: '1'},
        {option: 'No', value: '0'}
      ]} optionText="option" optionValue="value"/>
      <SelectInput source="X10ACNWHIGHLIGHT" name="X10ACNWHIGHLIGHT" label="Highlight" choices={[
        {option: 'Yes', value: '1'},
        {option: 'No', value: '0'}
      ]} optionText="option" optionValue="value"/>
      <div className="two-field-row">
        <CustomRichTextComponent source="X10ACNWLONGDESCR" label="Long description"/>
        <CustomRichTextComponent source="X10ACNWLONGDESCRENG" label="Long description English"/>
      </div>
      <DateInput label="Date From"
                 source="X10ACNWVISIBLEFROM"
                 name="X10ACNWVISIBLEFROM"
                 validate={isRequired}/>
      <DateInput label="Date To"
                 source="X10ACNWVISIBLETO"
                 name="X10ACNWVISIBLETO"
                 validate={isRequired}/>
      <NumberInput source="X10ACNWORDER"
                   name="X10ACNWORDER"
                   label="Order*"/>
      <TextField source="* 1 è la priorità massima"></TextField>
      <SelectInput source="X10ACNWEXTERNAL" name="X10ACNWEXTERNAL" label="Vis. Ext Users" choices={[
        {option: 'Yes', value: true},
        {option: 'No', value: false}
      ]} optionText="option" optionValue="value"/>
    </SimpleForm>
  </Edit>
);

const CustomPositionSelector = () => {
  const {setValue} = useFormContext();
  return <ReferenceInput onChange={(ev) => {
    if (ev === POSITION.flash) {
      const prefilledDateTo = new Date();
      prefilledDateTo.setDate(prefilledDateTo.getDate() + 14);
      setValue('X10ACNWVISIBLETO', prefilledDateTo);
    }
  }}
                         source="X10ACNWID_X10LCNP"
                         name="X10ACNWID_X10LCNP"
                         reference="company-news-position">
    <AutocompleteInput label="Position"
                       name="X10ACNWID_X10LCNP"
                       optionText="X10LCNPDESCRIZIONE"/>
  </ReferenceInput>
}


