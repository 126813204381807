const POSITION = {
  normal: 1,
  header: 2,
  spot: 3,
  globe: 4,
  opinions: 5,
  background: 6,
  alert: 7,
  linkedin: 8,
  academy: 9,
  video: 10,
  flash: 11
}

export {POSITION}