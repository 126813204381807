import * as React from 'react';
import {Button, Menu, MenuItemLink, useResourceDefinitions} from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import LabelIcon from '@material-ui/icons/Label';
import {canClearCache, clearCache} from "./clearCache";

const clickClearCache = () => {
  clearCache().then(res => alert(res));
}

export const CustomMenu = props => {
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
  return (
    <Menu {...props}>
      {resources.filter(r => r.hasList).map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={(resource.options && resource.options.title) || resource.name}
          onClick={props.onMenuClick}
          leftIcon={<DefaultIcon/>}
        />
      ))}
      {canClearCache()
        ? <Button label="Clear Cache" onClick={() => clickClearCache()}>
          <LabelIcon/>
        </Button>
        : null}
    </Menu>
  );
}
