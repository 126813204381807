import moment from 'moment';
import {convertMomentDate} from '../util/util';

function updateCompanyNews(resource, params, dataProvider) {
  params.data.X10ACNWDATE = moment(params.data.X10ACNWDATE).format('YYYY-MM-DD');
  if (params.data.X10ACNWVISIBLEFROM) params.data.X10ACNWVISIBLEFROM = moment(params.data.X10ACNWVISIBLEFROM).format('YYYY-MM-DD');
  if (params.data.X10ACNWVISIBLETO) params.data.X10ACNWVISIBLETO = moment(params.data.X10ACNWVISIBLETO).format('YYYY-MM-DD');
  return dataProvider.update(resource, params);
}

function createCompanyNews(resource, params, dataProvider) {
  params.data.X10ACNWDATE = moment(params.data.X10ACNWDATE).format('YYYY-MM-DD');
  if (params.data.X10ACNWVISIBLEFROM) params.data.X10ACNWVISIBLEFROM = moment(params.data.X10ACNWVISIBLEFROM).format('YYYY-MM-DD');
  if (params.data.X10ACNWVISIBLETO) params.data.X10ACNWVISIBLETO = moment(params.data.X10ACNWVISIBLETO).format('YYYY-MM-DD');
  return dataProvider.create(resource, params);
}

function getListCompanyNews(resource, params, dataProvider) {
  return dataProvider.getList(resource, params).then(response => {
    response.data.map(item => convertMomentDate(item));
    return response;
  });
}

function getOneCompanyNews(resource, params, dataProvider) {
  return dataProvider.getOne(resource, params).then(response => {
    response.data.X10ACNWDATE = moment(response.data.X10ACNWDATE).toDate();
    if (response.data.X10ACNWVISIBLEFROM) response.data.X10ACNWVISIBLEFROM = moment(response.data.X10ACNWVISIBLEFROM).toDate();
    if (response.data.X10ACNWVISIBLETO) response.data.X10ACNWVISIBLETO = moment(response.data.X10ACNWVISIBLETO).toDate();
    return response;
  });
}

export {createCompanyNews, updateCompanyNews, getListCompanyNews, getOneCompanyNews};
