import React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  Pagination,
  ReferenceField,
  TextField,
  useRecordContext
} from "react-admin";
import {CustomBulkActionButtons} from "../../util/customBulkActionButtons";

const PostPagination = () => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>;

export const PositionUsersList = () => (
  <List perPage={100}
        pagination={<PostPagination/>}>
    <Datagrid bulkActionButtons={<CustomBulkActionButtons/>}>
      <UserField/>
      <ReferenceField label="Position"
                      source="X10AJOBPOSUSER_X10AJOBPOS"
                      reference='internal-job-positions/job-positions'>
        <TextField source="X10AJOBPOSTITLE"/>
      </ReferenceField>
      <ReferenceField label="Status"
                      link={false}
                      source="X10AJOBPOSUSER_X10LJOBSTATUS"
                      reference='internal-job-positions/statuses'>
        <TextField source="X10LJOBSTATUSDESCRIPTION"/>
      </ReferenceField>
      <DateField source="X10AJOBPOSUSERDATE"
                 label="Date"/>
      <EditButton/>
    </Datagrid>
  </List>
);

const UserField = () => {
  const record = useRecordContext();
  return record.userFullName
    ? <TextField source="userFullName"/>
    : <ReferenceField label="User"
                      link={false}
                      source="X10AJOBPOSUSER_AUTE"
                      reference='users'>
      <TextField source="C_NOMINATIVO"/>
    </ReferenceField>;
}
