import moment from 'moment';
import {POSITION} from "./enum";

const getOptions = options => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return options;
};


const convertMomentDate = (item) => {
  item.X10ACNWDATE = moment(item.X10ACNWDATE).toDate();
  if (item.X10ACNWVISIBLEFROM) item.X10ACNWVISIBLEFROM = moment(item.X10ACNWVISIBLEFROM).toDate();
  if (item.X10ACNWVISIBLETO) item.X10ACNWVISIBLETO = moment(item.X10ACNWVISIBLETO).toDate();
}

const attachmentFileName = (url) => {
  if (url) {
    let lastSlash = 0;
    for (let i = 0; i < url.length; i++) {
      if (url[i] === '/') {
        lastSlash = i
      }
    }
    const indexQuestion = url.indexOf('?', lastSlash);
    const fileName = url.slice(lastSlash + 1, indexQuestion > 0 ? indexQuestion : url.length);
    return fileName.replace(/%20/g, ' ');
  }
  return ''
}

const isOpinionPositionSelected = positionValue => positionValue === POSITION.opinions;

export {getOptions, attachmentFileName, convertMomentDate, isOpinionPositionSelected};
