import React from 'react';
import {
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  Filter,
  List,
  NumberField,
  Pagination,
  SelectField,
  SelectInput,
  TextField,
  TextInput
} from 'react-admin';
import {CustomBulkActionButtons} from "../util/customBulkActionButtons";
import {EllipsisText} from "../util/ellipsis-text";
import {SimpleSelectField} from "../util/simple-select-field";

const PostPagination = () => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>;

const CompanyNewsListFilter = (props) => (
  <Filter {...props} sanitizeEmptyValues>
    <TextInput label="Title" source="X10ACNWTITLE" name="X10ACNWTITLE"/>
    <TextInput label="Tag" source="X10TCNGCODE" name="X10TCNGCODE"/>
    <TextInput label="Type" source="X10TCNTCODE" name="X10TCNTCODE"/>
    <TextInput label="Position" source="X10LCNPDESCRIZIONE" name="X10LCNPDESCRIZIONE"/>
    <SimpleSelectField label="Country"
                       source="X10ACNWID_X10LNAZ"
                       endpoint="countries"
                       sort={{field: 'LNAZDESCRIZIONE'}}
                       params={'X10TGEOCNW_NOT_NULL=true'}
                       perPage={1000}
                       optionText="LNAZDESCRIZIONE"/>
    <DateInput label="Date" source="X10ACNWDATE" name="X10ACNWDATE"/>
    <DateInput label="Visible from" source="FROMDATE" name="FROMDATE"/>
    <DateInput label="Visible to" source="TODATE" name="TODATE"/>
    <SelectInput source="X10ACNWACTIVE" label="Active" choices={[
      {option: 'Yes', value: '1'},
      {option: 'No', value: '0'}
    ]} optionText="option" optionValue="value"/>
  </Filter>
);

export const CompanyNewsList = () => (
  <List perPage={100}
        filters={<CompanyNewsListFilter/>}
        pagination={<PostPagination/>}>
    <Datagrid bulkActionButtons={<CustomBulkActionButtons/>}>
      <SelectField source="X10ACNWITA" label="Vis. Italian" choices={[
        {option: 'Yes', value: true},
        {option: 'No', value: false}
      ]} optionText="option" optionValue="value"/>
      <SelectField source="X10ACNWENG" label="Vis. English" choices={[
        {option: 'Yes', value: true},
        {option: 'No', value: false}
      ]} optionText="option" optionValue="value"/>
      <TextField source="X10ACNWTITLE"
                 label="Title"/>
      <DateField source="X10ACNWDATE"
                 label="Date"/>
      <EllipsisText source="X10ACNWSUBTITLE"
                    label="Subtitle"/>
      <EllipsisText source="X10ACNWSHORTDESCR"
                    label="Short description"/>
      <TextField source="X10TCNTCODE"
                 label="Type"/>
      <TextField source="X10TCNGCODE"
                 label="Tag"/>
      <TextField source="X10LCNPDESCRIZIONE"
                 label="Position"/>
      <SelectField source="X10ACNWPHOTO" label="Visualizza Foto" choices={[
        {option: 'Yes', value: '1'},
        {option: 'No', value: '0'}
      ]} optionText="option" optionValue="value"/>
      <SelectField source="X10ACNWACTIVE" label="Active" choices={[
        {option: 'Yes', value: '1'},
        {option: 'No', value: '0'}
      ]} optionText="option" optionValue="value"/>
      <SelectField source="X10ACNWHIGHLIGHT" label="Highlight" choices={[
        {option: 'Yes', value: '1'},
        {option: 'No', value: '0'}
      ]} optionText="option" optionValue="value"/>
      <DateField label="Date From"
                 source="X10ACNWVISIBLEFROM"/>
      <DateField label="Date To"
                 source="X10ACNWVISIBLETO"/>
      <NumberField source="X10ACNWORDER"
                   label="Order"/>
      <EditButton/>
    </Datagrid>
  </List>
);
