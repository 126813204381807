import React from "react";
import {Datagrid, DateField, EditButton, List, Pagination, SelectField, TextField} from "react-admin";
import {CustomBulkActionButtons} from "../../util/customBulkActionButtons";

const PostPagination = () => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>;

export const JobPositionsList = () => (
  <List perPage={100}
        pagination={<PostPagination/>}>
    <Datagrid bulkActionButtons={<CustomBulkActionButtons/>}>
      <TextField source="X10AJOBPOSTITLE"
                 label="Title"/>
      <SelectField source="X10AJOBPOSACTIVE" label="Active" choices={[
        {option: 'Yes', value: true},
        {option: 'No', value: false}
      ]} optionText="option" optionValue="value"/>
      <DateField source="X10AJOBPOSSTARTDATE"
                 label="Start Date"/>
      <DateField source="X10AJOBPOSENDDATE"
                 label="End Date"/>
      <EditButton/>
    </Datagrid>
  </List>
);