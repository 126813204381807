import React from 'react';
import {Datagrid, EditButton, Filter, List, Pagination, TextField, TextInput} from 'react-admin';
import {CustomBulkActionButtons} from "../util/customBulkActionButtons";


const PostPagination = () => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>;

const CompanyNewsTagListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Code" source="X10TCNGCODE" name="X10TCNGCODE"/>
    <TextInput label="Description" source="X10TCNGDESCRIPTION" name="X10TCNGDESCRIPTION"/>
  </Filter>
);

export const CompanyNewsTagList = () => (
  <List perPage={100}
        filters={<CompanyNewsTagListFilter/>}
        pagination={<PostPagination/>}>
    <Datagrid bulkActionButtons={<CustomBulkActionButtons/>}>
      <TextField source="X10TCNGCODE"
                 label="Code"/>
      <TextField source="X10TCNGDESCRIPTION"
                 label="Description"/>
      <EditButton/>
    </Datagrid>
  </List>
);



