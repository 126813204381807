import React from 'react';
import {Create, SimpleForm, TextInput,} from 'react-admin';
import {isRequired} from "../util/validation";


export const CompanyNewsTypeCreate = () => (
  <Create>
    <SimpleForm redirect="list">
      <TextInput source="X10TCNTCODE"
                 name="X10TCNTCODE"
                 validate={isRequired}
                 label="Code"/>
      <TextInput source="X10TCNTDESCRIPTION"
                 name="X10TCNTDESCRIPTION"
                 validate={isRequired}
                 label="Description"/>
    </SimpleForm>
  </Create>
);