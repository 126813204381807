import React from 'react';
import ReactQuill from "react-quill";
import {useInput} from "react-admin";
import {emptyRichText} from "./constants";

export const CustomRichTextComponent = (props) => {
  const {
    field,
  } = useInput(props);
  const {source, label, required} = props;
  const onChange = (value) => {
    if (emptyRichText.includes(value)) {
      field.onChange('');
      return;
    }
    field.onChange(value);
  };
  const className = () => !required || field.value ? 'width-50' : 'width-50 invalid';
  return (
    <div className={className()}>
      <label className="rich-text-label" htmlFor={source}>{label}</label>
      <ReactQuill
        id={source}
        value={field.value}
        onChange={onChange}
      />
    </div>
  );
}
