import {hasGroupsDetails, login, logout} from "./msalAuthProvider";
import {ADMIN_GROUP} from './util/constants'

function isAdmin(groups) {
  return groups && groups.includes(ADMIN_GROUP);
}

const authProvider = {
  login: () => login(),
  logout: () => logout(),
  checkAuth: () => hasGroupsDetails() ? Promise.resolve() : logout(true),
  checkError: (error) => {
    const status = error.status;
    const isUnauthorized = status === 401 || status === 403;
    if (isUnauthorized) {
      return logout(true);
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const groups = JSON.parse(localStorage.getItem('groups'));
    const admin = isAdmin(groups);
    return admin ? Promise.resolve({admin}) : Promise.reject();
  }
};

export default authProvider;
