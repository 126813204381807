import {Config} from "./config/config";
import {ADMIN_GROUP} from "./util/constants";

function isAdmin(groups) {
  return groups && groups.includes(ADMIN_GROUP);
}

export function canClearCache() {
  const groups = JSON.parse(localStorage.getItem('groups'));
  return isAdmin(groups);
}

export async function clearCache() {
  const token = localStorage.getItem('token');
  const request = new Request(Config.serverUrl + '/env/clear-cache', {
    method: 'GET',
    headers: new Headers({'Authorization': `Bearer ${token}`})
  });
  const res = await fetch(request);
  if (!res.ok) {
    return `${res.statusText} - cache could not be cleared`;
  }
  return 'Cache cleared!'
}