import React from 'react';
import {DeleteButton, SaveButton, Toolbar} from 'react-admin';

export const CustomEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton/>
    <DeleteButton {...props} undoable="false"/>
  </Toolbar>
);

export const CustomEditDisableInvalidToolbar = props => (
  <Toolbar {...props} >
    <SaveButton disabled={props.invalid}/>
    <DeleteButton {...props} undoable="false"/>
  </Toolbar>
);

export const CustomEditNoDeleteToolbar = props => (
  <Toolbar {...props} >
    <SaveButton/>
  </Toolbar>
);