import React from 'react';
import {AutocompleteInput, Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin';
import {validateSharepointFile} from "../util/validation";
import {CustomEditDisableInvalidToolbar} from "../util/customEditToolbar";
import {useWatch} from 'react-hook-form';
import {BiLinkExternal} from "react-icons/bi";
import {attachmentFileName} from "../util/util"

const validateForm = (values) => {
  return validateSharepointFile(values.X10ACNASHAREPOINTFILE);
};

export const CompanyNewsAttachmentsEdit = () => (
  <Edit undoable="false">
    <SimpleForm redirect="list"
                sanitizeEmptyValues
                mode={"onBlur"}
                reValidateMode={"onBlur"}
                validate={validateForm}
                toolbar={<CustomEditDisableInvalidToolbar/>}>
      <TextInput disabled label="Id"
                 source="X10ACNAID"
                 name="X10ACNAID"/>
      <ReferenceInput source="X10ACNAID_X10ACNW"
                      name="X10ACNAID_X10ACNW"
                      reference="company-news">
        <AutocompleteInput name="X10ACNWTITLE"
                           label="News_Title"
                           disabled={true}
                           optionText="X10ACNWTITLE"/>
      </ReferenceInput>
      <SelectInput source="X10ACNAHIGHLIGHT" label="Highlight" choices={[
        {option: 'Yes', value: '1'},
        {option: 'No', value: '0'},
      ]} optionText="option" optionValue="value"/>
      <NumberInput source="X10ACNAORDER" name="X10ACNAORDER" label="Order"/>
      <div style={{display: 'flex', width: '100%'}}>
        <TextInput fullWidth source="X10ACNASHAREPOINTFILE" name="X10ACNASHAREPOINTFILE"
                   label="Attachment Link"/>
        <UrlFileActions source="X10ACNASHAREPOINTFILE"/>
      </div>
      <CustomText/>
    </SimpleForm>
  </Edit>
);

const UrlFileActions = props => {
  const attachmentUrl = useWatch({name: 'X10ACNASHAREPOINTFILE'});
  const validUrl = !validateSharepointFile(attachmentUrl).X10ACNASHAREPOINTFILE;
  return <div style={{fontSize: '1.5rem', display: 'flex', padding: '1.2rem', flexGrow: 1}}>
    <a target="_blank" rel="noopener noreferrer" href={attachmentUrl}>
      {validUrl && <BiLinkExternal style={{cursor: 'pointer'}}/>}
    </a>
  </div>
}

const CustomText = props => {
  const attachmentUrl = useWatch({name: 'X10ACNASHAREPOINTFILE'});
  const validUrl = !validateSharepointFile(attachmentUrl).X10ACNASHAREPOINTFILE;
  return validUrl && <p style={{margin: 0, marginTop: '-1rem'}}>{attachmentFileName(attachmentUrl)}</p>
}
