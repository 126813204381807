import React from 'react';
import {Create, SimpleForm, TextInput,} from 'react-admin';
import {isRequired} from "../util/validation";


export const CompanyNewsTagCreate = () => (
  <Create>
    <SimpleForm redirect="list">
      <TextInput source="X10TCNGCODE"
                 name="X10TCNGCODE"
                 validate={isRequired}
                 label="Code"/>
      <TextInput source="X10TCNGDESCRIPTION"
                 name="X10TCNGDESCRIPTION"
                 validate={isRequired}
                 label="Description"/>
    </SimpleForm>
  </Create>
);