import React from "react";
import {Datagrid, EditButton, Filter, List, Pagination, ReferenceField, TextField, TextInput} from "react-admin";
import {SimpleSelectField} from "../util/simple-select-field";

const PostPagination = () => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>;

const CompanyNewsCountriesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Description" source="LNAZDESCRIZIONE" name="LNAZDESCRIZIONE"/>
    <TextInput label="English Description" source="LNAZDESCRIZIONEENG" name="LNAZDESCRIZIONEENG"/>
    <SimpleSelectField label="Geographical Area"
                       source="X10LNAZID_X10TGEOCNW"
                       endpoint="geography"
                       sort={{field: 'X10TGEODESCRIZIONE'}}
                       perPage={1000}
                       optionText="X10TGEODESCRIZIONE"/>
  </Filter>
);

export const CompanyNewsCountriesList = () => (
  <List perPage={100}
        filters={<CompanyNewsCountriesFilter/>}
        pagination={<PostPagination/>}>
    <Datagrid>
      <TextField label="Code"
                 source="LNAZSIGLA"/>
      <TextField label="Description"
                 source="LNAZDESCRIZIONE"/>
      <TextField label="English Description"
                 source="LNAZDESCRIZIONEENG"/>
      <ReferenceField label="Geographical Area"
                      source="X10LNAZID_X10TGEOCNW"
                      reference='geography'>
        <TextField source="X10TGEODESCRIZIONE"/>
      </ReferenceField>
      <EditButton/>
    </Datagrid>
  </List>
);
