import React from 'react';
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  Pagination,
  ReferenceField,
  SelectField,
  TextField,
  TextInput
} from 'react-admin';
import {CustomBulkActionButtons} from "../util/customBulkActionButtons";
import {attachmentFileName} from "../util/util";

const CompanyNewsAttachmentsListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Article Title"
               source="X10ACNWTITLE"
               name="X10ACNWTITLE"
    />
  </Filter>
);

const PostPagination = () => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>;

export const CompanyNewsAttachmentsList = () => (
  <List perPage={100}
        filters={<CompanyNewsAttachmentsListFilter/>}
        pagination={<PostPagination/>}>
    <Datagrid bulkActionButtons={<CustomBulkActionButtons/>}>
      <ReferenceField label="Article Title"
                      source="X10ACNAID_X10ACNW"
                      sortable={false}
                      reference="company-news">
        <TextField source="X10ACNWTITLE"/>
      </ReferenceField>
      <SelectField source="X10ACNAHIGHLIGHT" label="Highlight" choices={[
        {option: 'Yes', value: '1'},
        {option: 'No', value: '0'},
      ]} optionText="option" optionValue="value"/>
      <TextField source="X10ACNAORDER" label="Order"/>
      <MyUrlField source="X10ACNAID"
                  label="File name"/>
      <EditButton/>
    </Datagrid>
  </List>
);

const MyUrlField = ({record = {}}) => {
  return <a target="_blank" rel="noopener noreferrer"
            href={record['X10ACNASHAREPOINTFILE']}>{attachmentFileName(record.X10ACNASHAREPOINTFILE)}</a>

}




