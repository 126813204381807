import React from 'react';
import {Admin, Resource} from 'react-admin';
import './App.css';
import authProvider from './authProvider';
import {CompanyNewsList} from './company-news/company-news-list';
import {CompanyNewsEdit} from './company-news/company-news-edit';
import {CompanyNewsCreate} from './company-news/company-news-create';
import {CompanyNewsTypeList} from './company-news-type/company-news-type-list';
import {CompanyNewsTypeCreate} from './company-news-type/company-news-type-create';
import {CompanyNewsTypeEdit} from './company-news-type/company-news-type-edit';
import {CompanyNewsTagList} from './company-news-tag/company-news-tag-list';
import {CompanyNewsPositionList} from './company-news-position/company-news-position-list';
import {CompanyNewsTagCreate} from './company-news-tag/company-news-tag-create';
import {CompanyNewsTagEdit} from './company-news-tag/company-news-tag-edit';
import {CompanyNewsAttachmentsList} from './company-news-attachments/company-news-attachments-list';
import {CompanyNewsAttachmentsCreate} from './company-news-attachments/company-news-attachments-create';
import {CompanyNewsAttachmentsEdit} from './company-news-attachments/company-news-attachments-edit';
import {CompanyNewsCountriesList} from './company-news-countries/company-news-countries-list';
import {CompanyNewsCountriesEdit} from './company-news-countries/company-news-countries-edit';
import packageInfo from '../package.json';

//PrimeReact imports
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import customDataProvider from './customDataProvider';
import Login from './login/Login';
import {CustomLayout} from './CustomLayout';
import {JobPositionsList} from './internal-job-positions/job-positions/job-positions-list';
import {PositionUsersList} from './internal-job-positions/position-users/position-users-list';
import {JobPositionsEdit} from './internal-job-positions/job-positions/job-positions-edit';
import {PositionUsersEdit} from './internal-job-positions/position-users/position-users-edit';
import {JobPositionsCreate} from './internal-job-positions/job-positions/job-positions-create';

const myLoginPage = () => <Login backgroundImage="/background.jpg"/>

const OnlyIfEditorOrAdmin = (permissions, component) =>
  permissions.admin
    ? component
    : null

const App = () => (
  <div>
    <Admin authProvider={authProvider}
           dataProvider={customDataProvider}
           layout={CustomLayout}
           loginPage={myLoginPage}>
      {permissions => permissions ? [
        OnlyIfEditorOrAdmin(permissions, <Resource name="company-news"
                                                   options={{title: 'News'}}
                                                   list={CompanyNewsList}
                                                   edit={CompanyNewsEdit}
                                                   create={CompanyNewsCreate}/>),
        OnlyIfEditorOrAdmin(permissions, <Resource name="company-news-type"
                                                   options={{title: 'Types'}}
                                                   list={CompanyNewsTypeList}
                                                   edit={CompanyNewsTypeEdit}
                                                   create={CompanyNewsTypeCreate}/>),
        OnlyIfEditorOrAdmin(permissions, <Resource name="company-news-tag"
                                                   options={{title: 'Tags'}}
                                                   list={CompanyNewsTagList}
                                                   edit={CompanyNewsTagEdit}
                                                   create={CompanyNewsTagCreate}/>),
        OnlyIfEditorOrAdmin(permissions, <Resource name="company-news-attachments"
                                                   options={{title: 'Attachments'}}
                                                   list={CompanyNewsAttachmentsList}
                                                   create={CompanyNewsAttachmentsCreate}
                                                   edit={CompanyNewsAttachmentsEdit}/>),
        OnlyIfEditorOrAdmin(permissions, <Resource name="company-news-position"
                                                   options={{title: 'Positions'}}
                                                   list={CompanyNewsPositionList}/>),
        OnlyIfEditorOrAdmin(permissions, <Resource name="countries"
                                                   options={{title: 'Countries'}}
                                                   list={CompanyNewsCountriesList}
                                                   edit={CompanyNewsCountriesEdit}/>),
        OnlyIfEditorOrAdmin(permissions, <Resource name="internal-job-positions/job-positions"
                                                   options={{title: 'Job Positions'}}
                                                   create={JobPositionsCreate}
                                                   list={JobPositionsList}
                                                   edit={JobPositionsEdit}/>),
        OnlyIfEditorOrAdmin(permissions, <Resource name="internal-job-positions/position-users"
                                                   options={{title: 'Job Applicants'}}
                                                   list={PositionUsersList}
                                                   edit={PositionUsersEdit}/>),
        OnlyIfEditorOrAdmin(permissions, <Resource name="internal-job-positions/statuses"/>),
        OnlyIfEditorOrAdmin(permissions, <Resource name="users"/>),
        OnlyIfEditorOrAdmin(permissions, <Resource name='geography'/>)
      ] : null}
    </Admin>
    <div style={{
      position: 'fixed', right: 0, bottom: 0, left: 0, zIndex: 100,
      padding: 6,
      backgroundColor: '#efefef',
      textAlign: 'center'
    }}><small>Overme Admin v{packageInfo.version}</small></div>
  </div>

);

export default App;
