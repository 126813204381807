import React from "react";
import {Edit, SimpleForm, TextInput} from "react-admin";
import {SimpleSelectField} from "../util/simple-select-field";
import {isRequired} from "../util/validation";
import {CustomEditNoDeleteToolbar} from "../util/customEditToolbar";

export const CompanyNewsCountriesEdit = () => (
  <Edit undoable="false">
    <SimpleForm redirect="list" toolbar={<CustomEditNoDeleteToolbar/>}>
      <TextInput disabled label="Code"
                 source="LNAZSIGLA"
                 name="LNAZSIGLA"/>
      <TextInput disabled label="Description"
                 source="LNAZDESCRIZIONE"
                 name="LNAZDESCRIZIONE"/>
      <TextInput label="English Description"
                 source="LNAZDESCRIZIONEENG"
                 name="LNAZDESCRIZIONEENG"/>
      <SimpleSelectField label="Geographical Area"
                         source="X10LNAZID_X10TGEOCNW"
                         endpoint='geography'
                         optionText="X10TGEODESCRIZIONE"
                         validate={isRequired}/>
    </SimpleForm>
  </Edit>
);
