import React from "react";
import {useLogin, useNotify} from 'react-admin';
import {Card} from 'primereact/card';
import {Button} from "primereact/button";
import './Login.css';

const Login = ({backgroundImage}) => {
  const login = useLogin();
  const notify = useNotify();

  const submit = (e) => {
    e.preventDefault();
    login({}, '/').catch(notify);
  }

  const backgroundStyle = {backgroundImage: `url(${backgroundImage})`}
  return (
    <div style={backgroundStyle} className='fullSize'>
      <Card className="ui-card-shadow cardStyle" title='Overme - Admin'>
        <Button className='loginButton' onClick={submit} label="Login"/>
      </Card>
    </div>
  );
}

export default Login;
