import React from 'react';
import {Create, NumberInput, SelectInput, SimpleForm, TextInput} from 'react-admin';
import {isRequired, validateSharepointFile} from '../util/validation';
import {SimpleSelectField} from '../util/simple-select-field';
import {useWatch} from 'react-hook-form';
import {BiLinkExternal} from 'react-icons/bi';
import {attachmentFileName} from "../util/util";
import {CustomCreateToolbar} from "../util/customCreateToolbar";

const validateForm = (values) => {
  const errors = validateSharepointFile(values.X10ACNASHAREPOINTFILE);
  if (!values.X10ACNAID_X10ACNW) {
    errors.X10ACNAID_X10ACNW = 'Required';
  }
  return errors;
};

export const CompanyNewsAttachmentsCreate = () => (
  <Create>
    <SimpleForm redirect="list"
                mode={"onBlur"}
                reValidateMode={"onBlur"}
                sanitizeEmptyValues
                validate={validateForm}
                toolbar={<CustomCreateToolbar/>}>
      <SimpleSelectField label="News_Title"
                         source="X10ACNAID_X10ACNW"
                         endpoint="company-news"
                         validate={isRequired}
                         sort={{field: 'id'}}
                         searchable={true}
                         order={'DESC'}
                         parse={value => Number(value)}
                         params={'X10ACNWACTIVE=1'}
                         optionText="X10ACNWTITLE"
                         perPage={200}/>
      <SelectInput source="X10ACNAHIGHLIGHT" defaultValue="0" label="Highlight" choices={[
        {option: 'Yes', value: '1'},
        {option: 'No', value: '0'}
      ]} optionText="option" optionValue="value"/>
      <NumberInput source="X10ACNAORDER" name="X10ACNAORDER" label="Order"/>
      <div style={{display: 'flex', width: '100%'}}>
        <TextInput fullWidth source="X10ACNASHAREPOINTFILE" name="X10ACNASHAREPOINTFILE"
                   label="Attachment Link"/>
        <UrlFileActions source="X10ACNASHAREPOINTFILE"/>
      </div>
      <CustomText/>
    </SimpleForm>
  </Create>
);

const UrlFileActions = props => {
  const attachmentUrl = useWatch({name: 'X10ACNASHAREPOINTFILE'});
  const validUrl = !validateSharepointFile(attachmentUrl).X10ACNASHAREPOINTFILE;
  return <div style={{fontSize: '1.5rem', display: 'flex', padding: '1.2rem', flexGrow: 1}}>
    <a target="_blank" rel="noopener noreferrer" href={attachmentUrl}>
      {validUrl && <BiLinkExternal style={{cursor: 'pointer'}}/>}
    </a>
  </div>
}

const CustomText = props => {
  const attachmentUrl = useWatch({name: 'X10ACNASHAREPOINTFILE'});
  const validUrl = !validateSharepointFile(attachmentUrl).X10ACNASHAREPOINTFILE;
  return validUrl && <p style={{margin: 0, marginTop: '-1rem'}}>{attachmentFileName(attachmentUrl)}</p>
}
