import {fetchUtils} from 'react-admin';
import simpleRestProvider from 'ra-data-json-server';
import {Config} from './config/config';
import {
  createCompanyNews,
  getListCompanyNews,
  getOneCompanyNews,
  updateCompanyNews
} from './data-providers/company-news';
import {createCompanyNewsAttachments, updateCompanyNewsAttachments} from './data-providers/company-news-attachments';
import {createJobPositions, updateJobPositions} from "./data-providers/internal-job-positions";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
};
const dataProvider = simpleRestProvider(Config.serverUrl, httpClient);
const customDataProvider = {
  ...dataProvider,
  update: (resource, params) => {
    switch (resource) {
      case 'company-news-attachments':
        return updateCompanyNewsAttachments(resource, params, dataProvider);
      case 'company-news':
        return updateCompanyNews(resource, params, dataProvider);
      case 'internal-job-positions/job-positions':
        return updateJobPositions(resource, params, dataProvider);
      default:
        return dataProvider.update(resource, params);
    }
  },
  create: (resource, params) => {
    switch (resource) {
      case 'company-news-attachments':
        return createCompanyNewsAttachments(resource, params, dataProvider);
      case 'company-news':
        return createCompanyNews(resource, params, dataProvider);
      case 'internal-job-positions/job-positions':
        return createJobPositions(resource, params, dataProvider);
      default:
        return dataProvider.create(resource, params);
    }
  },
  getList: (resource, params) => {
    switch (resource) {
      case 'company-news':
        return getListCompanyNews(resource, params, dataProvider);
      default:
        return dataProvider.getList(resource, params);
    }
  },
  getOne: (resource, params) => {
    switch (resource) {
      case 'company-news':
        return getOneCompanyNews(resource, params, dataProvider);
      default:
        return dataProvider.getOne(resource, params);
    }
  }
};

export default customDataProvider;
