import React from 'react';
import {Datagrid, EditButton, Filter, List, Pagination, TextField, TextInput} from 'react-admin';
import {CustomBulkActionButtons} from "../util/customBulkActionButtons";


const PostPagination = () => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>;

const CompanyNewsTypeListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Code" source="X10TCNTCODE" name="X10TCNTCODE"/>
    <TextInput label="Description" source="X10TCNTDESCRIPTION" name="X10TCNTDESCRIPTION"/>
  </Filter>
);

export const CompanyNewsTypeList = () => (
  <List perPage={100}
        filters={<CompanyNewsTypeListFilter/>}
        pagination={<PostPagination/>}>
    <Datagrid bulkActionButtons={<CustomBulkActionButtons/>}>
      <TextField source="X10TCNTCODE"
                 label="Code"/>
      <TextField source="X10TCNTDESCRIPTION"
                 label="Description"/>
      <EditButton/>
    </Datagrid>
  </List>
);
