import React from 'react';
import {Edit, SimpleForm, TextInput,} from 'react-admin';
import {isRequired} from "../util/validation";
import {CustomEditToolbar} from "../util/customEditToolbar";


export const CompanyNewsTagEdit = () => (
  <Edit undoable="false">
    <SimpleForm redirect="list" toolbar={<CustomEditToolbar/>}>
      <TextInput source="X10TCNGCODE"
                 name="X10TCNGCODE"
                 validate={isRequired}
                 label="Code"/>
      <TextInput source="X10TCNGDESCRIPTION"
                 name="X10TCNGDESCRIPTION"
                 validate={isRequired}
                 label="Description"/>
    </SimpleForm>
  </Edit>
);


