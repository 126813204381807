import React from 'react';
import {Datagrid, List, Pagination, TextField} from 'react-admin';
import {CustomBulkActionButtons} from "../util/customBulkActionButtons";


const PostPagination = () => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>;

export const CompanyNewsPositionList = () => (
  <List perPage={100}
        pagination={<PostPagination/>}>
    <Datagrid bulkActionButtons={<CustomBulkActionButtons/>}>
      <TextField source="X10LCNPID"
                 label="Id"/>
      <TextField source="X10LCNPDESCRIZIONE"
                 label="Description"/>
    </Datagrid>
  </List>
);



