import {Config} from "./config/config";
import {BrowserCacheLocation, PublicClientApplication} from "@azure/msal-browser";

const authConfig = {
  msalConfig: {
    auth: {
      clientId: '408b00f9-c2de-4a72-b0b4-f5010bbdf42d',
      authority: 'https://login.microsoftonline.com/09b271bc-ab5f-4085-b239-7132f983f363',
      redirectUri: Config.MSAL_REDIRECT_URI,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true
    }
  },
  authParams: {
    scopes: [
      'api://408b00f9-c2de-4a72-b0b4-f5010bbdf42d/overme-backend'
    ]
  }
};

const msalAuthProvider = new PublicClientApplication(authConfig.msalConfig)

/**
 * Handle the result of the redirect login
 */
msalAuthProvider.handleRedirectPromise().then(async authResponse => {
  if (!authResponse) {
    window.location.href = '#/login';
    return;
  }
  localStorage.setItem('token', authResponse?.accessToken);
  const token = localStorage.getItem('token');
  if (token) {
    await getUserGroups(token);
    window.location.href = '#/';
  }
}).catch(error => {
  throw error
});

/**
 * If the acquireTokenSilent fails the app will redirect to the MS login screen
 */
export async function login() {
  try {
    const authResponse = await msalAuthProvider.acquireTokenSilent(authConfig.authParams);
    localStorage.setItem('token', authResponse?.accessToken);
  } catch (acquireTokenError) {
    try {
      await msalAuthProvider.loginRedirect(authConfig.authParams);
    } catch (loginRedirectError) {
      alert("You don't have access to Overme Admin");
    }
    throw acquireTokenError;
  }
}

export async function logout(shouldReject = false) {
  if (!!msalAuthProvider.getActiveAccount()) {
    await msalAuthProvider.logout();
  }
  localStorage.clear();
  return shouldReject ? Promise.reject({redirectTo: '/login'}) : Promise.resolve();
}

export async function getUserGroups(token) {
  const request = new Request(Config.serverUrl + '/users/user-groups', {
    method: 'GET',
    headers: new Headers({'Content-Type': 'application/json'})
  });
  request.headers.set('Authorization', `Bearer ${token}`);
  try {
    const res = await fetch(request);
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const groups = await res.json();
    localStorage.setItem('groups', JSON.stringify(groups));
  } catch (e) {
    alert(e);
  }
}

export function hasGroupsDetails() {
  return !!localStorage.getItem('token') && !!localStorage.getItem('groups');
}
