import {AutocompleteInput, DateInput, Edit, ReferenceInput, SimpleForm, TextInput, useRecordContext} from 'react-admin';
import {CustomEditDisableInvalidToolbar} from "../../util/customEditToolbar";
import React from "react";
import {SimpleSelectField} from "../../util/simple-select-field";
import {BiDownload} from "react-icons/bi";
import {useWatch} from "react-hook-form";

export const PositionUsersEdit = () => (
  <Edit undoable="false">
    <SimpleForm redirect="list"
                toolbar={<CustomEditDisableInvalidToolbar/>}>
      <UserField/>
      <div className="two-field-row">
        <SimpleSelectField label="Job Position"
                           source="X10AJOBPOSUSER_X10AJOBPOS"
                           endpoint="internal-job-positions/job-positions"
                           optionText="X10AJOBPOSTITLE"
                           width={"100%"}
                           disabled={true}
                           fieldWidth={"50%"}
                           perPage={1000}/>
        <SimpleSelectField label="Status"
                           source="X10AJOBPOSUSER_X10LJOBSTATUS"
                           endpoint="internal-job-positions/statuses"
                           optionText="X10LJOBSTATUSDESCRIPTION"
                           fieldWidth={"50%"}
                           width={"100%"}/>
      </div>
      <DateInput label="Date"
                 disabled={true}
                 source="X10AJOBPOSUSERDATE"
                 name="X10AJOBPOSUSERDATE"/>
      <div className="two-field-row">
        <TextInput className="width-50" source="X10AJOBPOSUSERDESCRIPTION" name="X10AJOBPOSUSERDESCRIPTION" minRows={10}
                   multiline={true}
                   disabled={true} label="Description"/>
        <TextInput className="width-50" source="X10AJOBPOSUSERRESPONSE" name="X10AJOBPOSUSERRESPONSE" minRows={10}
                   multiline={true}
                   label="Response"/>
      </div>
      <AttachmentLink/>
    </SimpleForm>
  </Edit>
);

const UserField = () => {
  const record = useRecordContext();
  return record.userFullName
    ? <TextInput source="userFullName"
                 name="userFullName"
                 className="width-50"
                 disabled={true}
                 label="User"/>
    : <ReferenceInput source="X10AJOBPOSUSER_AUTE"
                      name="X10AJOBPOSUSER_AUTE"
                      reference="users">
      <AutocompleteInput label="User"
                         name="C_NOMINATIVO"
                         fullWidth
                         disabled={true}
                         optionText="C_NOMINATIVO"/>
    </ReferenceInput>;
}

const AttachmentLink = props => {
  const attachment = useWatch({name: 'attachment'});
  if (!attachment) {
    return null;
  }
  return <div style={{fontSize: '1.5rem', display: 'flex', padding: '1.2rem', flexGrow: 1}}>
    <a style={{marginRight: '0.5rem'}} target="_blank" rel="noopener noreferrer"
       href={attachment['@microsoft.graph.downloadUrl']}>
      <BiDownload style={{cursor: 'pointer'}}/>
    </a>
    Attachment: {attachment.name}
  </div>;
}