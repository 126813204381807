import moment from "moment";

function updateJobPositions(resource, params, dataProvider) {
  if (params.data.X10AJOBPOSSTARTDATE) {
    params.data.X10AJOBPOSSTARTDATE = moment(params.data.X10AJOBPOSSTARTDATE).format('YYYY-MM-DD');
  }
  if (params.data.X10AJOBPOSENDDATE) {
    params.data.X10AJOBPOSENDDATE = moment(params.data.X10AJOBPOSENDDATE).format('YYYY-MM-DD');
  }
  return dataProvider.update(resource, params);
}

function createJobPositions(resource, params, dataProvider) {
  if (params.data.X10AJOBPOSSTARTDATE) {
    params.data.X10AJOBPOSSTARTDATE = moment(params.data.X10AJOBPOSSTARTDATE).format('YYYY-MM-DD');
  }
  if (params.data.X10AJOBPOSENDDATE) {
    params.data.X10AJOBPOSENDDATE = moment(params.data.X10AJOBPOSENDDATE).format('YYYY-MM-DD');
  }
  return dataProvider.create(resource, params);
}

export {createJobPositions, updateJobPositions}