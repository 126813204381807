//custom validation
import {POSITION} from "./enum";

const required = (message = 'Required') => value => value ? undefined : message;

const isRequired = [required()];

const validateShortDescriptionLength = (article) => {
  const errors = {};
  if (article.X10ACNWID_X10LCNP === POSITION.flash) {
    if (article.X10ACNWSHORTDESCR && article.X10ACNWSHORTDESCR.length > 280) {
      errors.X10ACNWSHORTDESCR = 'short description for flash news should have no more than 280 characters';
    }
    if (article.X10ACNWSHORTDESCRENG && article.X10ACNWSHORTDESCRENG.length > 280) {
      errors.X10ACNWSHORTDESCRENG = 'short description for flash news should have no more than 280 characters';
    }
  }
  return errors;
}

const validateSharepointFile = (attachmentUrl) => {
  const errors = {};
  try {
    new URL(attachmentUrl)
  } catch (err) {
    errors.X10ACNASHAREPOINTFILE = 'invalid link';
  }
  return errors;
}

const validateJobPositionAttachment = (attachmentUrl) => {
  const errors = {};
  try {
    new URL(attachmentUrl)
  } catch (err) {
    errors.X10AJOBPOSATTACHMENT = 'invalid link';
  }
  return errors;
}

function checkDateRange(message, dateFrom, dateTo) {
  if (!dateTo || !dateFrom) {
    return message;
  }
  try {
    const dateStart = new Date(dateFrom),
      dateEnd = new Date(dateTo);
    if (dateStart > dateEnd) {
      return message;
    }
  } catch (err) {
    return "Invalid date";
  }
}

export {
  checkDateRange,
  isRequired,
  validateSharepointFile,
  validateJobPositionAttachment,
  validateShortDescriptionLength
};
