import React from 'react';
import {
  AutocompleteInput,
  Create,
  DateInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin';
import {checkDateRange, isRequired, validateShortDescriptionLength} from '../util/validation';
import {SimpleSelectField} from '../util/simple-select-field';
import {isOpinionPositionSelected} from '../util/util';
import {CustomCreateToolbar} from '../util/customCreateToolbar';
import {useFormContext} from 'react-hook-form';
import {POSITION} from '../util/enum';
import {CustomRichTextComponent} from "../util/customRichTextComponent";

const visibleFrom = () => new Date();

const visibleTo = () => {
  const date = new Date();
  date.setMonth(date.getMonth() + 2);
  return date;
};

const validateForm = (article) => {
  const errors = validateShortDescriptionLength(article);
  if (!article.X10ACNWTITLE) {
    errors.X10ACNWTITLE = 'Required';
  }
  if (!article.X10ACNWDATE) {
    errors.X10ACNWDATE = 'Required';
  }
  if (!article.X10ACNWID_X10TCNT) {
    errors.X10ACNWID_X10TCNT = 'Required';
  }
  if (!article.X10ACNWID_X10TCNG) {
    errors.X10ACNWID_X10TCNG = 'Required';
  }
  const dateRangeErrorMessage = checkDateRange(
    'Invalid Date range',
    article.X10ACNWVISIBLEFROM,
    article.X10ACNWVISIBLETO,
  );
  if (dateRangeErrorMessage) {
    errors.X10ACNWVISIBLEFROM = dateRangeErrorMessage;
    errors.X10ACNWVISIBLETO = dateRangeErrorMessage;
  }
  if (isOpinionPositionSelected(article.X10ACNWID_X10LCNP) && !article.X10ACNWID_X10ACNW) {
    errors.X10ACNWID_X10ACNW = 'Required';
  }
  return errors;
}

export const CompanyNewsCreate = () => (
  <Create>
    <SimpleForm redirect="list"
                sanitizeEmptyValues
                mode={"onBlur"}
                reValidateMode={"onBlur"}
                validate={validateForm}
                toolbar={<CustomCreateToolbar/>}>
      <div className="two-field-row">
        <SelectInput source="X10ACNWITA" name="X10ACNWITA" defaultValue={true} label="Vis. Italian"
                     choices={[
                       {option: 'Yes', value: true},
                       {option: 'No', value: false}
                     ]} optionText="option" optionValue="value"/>
        <SelectInput source="X10ACNWENG" name="X10ACNWENG" defaultValue={true} label="Vis. English"
                     choices={[
                       {option: 'Yes', value: true},
                       {option: 'No', value: false}
                     ]} optionText="option" optionValue="value"/>
      </div>
      <div className="two-field-row">
        <TextInput source="X10ACNWTITLE"
                   name="X10ACNWTITLE"
                   validate={isRequired}
                   fullWidth
                   label="Title"/>
        <TextInput source="X10ACNWTITLEENG"
                   name="X10ACNWTITLEENG"
                   fullWidth
                   label="Title English"/>
      </div>
      <div className="two-field-row">
        <TextInput source="X10ACNWSUBTITLE"
                   name="X10ACNWSUBTITLE"
                   fullWidth
                   label="Subtitle"/>
        <TextInput source="X10ACNWSUBTITLEENG"
                   name="X10ACNWSUBTITLEENG"
                   fullWidth
                   label="Subtitle English"/>
      </div>
      <DateInput source="X10ACNWDATE"
                 name="X10ACNWDATE"
                 validate={isRequired}
                 label="Date"/>
      <div className="two-field-row">
        <TextInput source="X10ACNWSHORTDESCR"
                   name="X10ACNWSHORTDESCR"
                   fullWidth
                   label="Short description"/>
        <TextInput source="X10ACNWSHORTDESCRENG"
                   name="X10ACNWSHORTDESCRENG"
                   fullWidth
                   label="Short description English"/>
      </div>
      <SimpleSelectField label="Type"
                         source="X10ACNWID_X10TCNT"
                         validate={isRequired}
                         endpoint="company-news-type"
                         parse={val => Number(val)}
                         perPage={1000}
                         width={"100%"}
                         optionText="X10TCNTCODE"/>
      <SimpleSelectField label="Tag"
                         source="X10ACNWID_X10TCNG"
                         validate={isRequired}
                         endpoint="company-news-tag"
                         parse={val => Number(val)}
                         perPage={1000}
                         width={"100%"}
                         optionText="X10TCNGDESCRIPTION"/>
      <CustomPositionSelector/>
      <FormDataConsumer>
        {({formData}) =>
          isOpinionPositionSelected(formData.X10ACNWID_X10LCNP)
          && <ReferenceInput source="X10ACNWID_X10ACNW"
                             name="X10ACNWID_X10ACNW"
                             reference="company-news">
            <AutocompleteInput label="News title"
                               validate={isRequired}
                               name="X10ACNWID_X10ACNW"
                               sort={{field: "X10ACNWDATE", order: 'DESC'}}
                               filter={{X10LCNPDESCRIZIONE: 'normal'}}
                               optionText="X10ACNWTITLE"/>
          </ReferenceInput>
        }
      </FormDataConsumer>
      <SimpleSelectField label="Country"
                         source="X10ACNWID_X10LNAZ"
                         allowEmpty={true}
                         endpoint="countries"
                         parse={val => Number(val)}
                         sort={{field: 'LNAZDESCRIZIONE'}}
                         params={'X10TGEOCNW_NOT_NULL=true'}
                         perPage={1000}
                         width={"100%"}
                         optionText="LNAZDESCRIZIONE"/>
      <TextInput source="X10ACNWNEWSEMAIL"
                 name="X10ACNWNEWSEMAIL"
                 fullWidth
                 label="User Email"/>
      <TextInput source="X10ACNWTEAM"
                 name="X10ACNWTEAM"
                 fullWidth
                 label="Team"/>
      <SelectInput source="X10ACNWPHOTO" name="X10ACNWPHOTO" label="Visualizza Foto" choices={[
        {option: 'Yes', value: '1'},
        {option: 'No', value: '0'}
      ]} optionText="option" optionValue="value"/>
      <SelectInput source="X10ACNWACTIVE" name="X10ACNWACTIVE" defaultValue="1" label="Active" choices={[
        {option: 'Yes', value: '1'},
        {option: 'No', value: '0'}
      ]} optionText="option" optionValue="value"/>
      <SelectInput source="X10ACNWHIGHLIGHT" name="X10ACNWHIGHLIGHT" defaultValue="0" label="Highlight" choices={[
        {option: 'Yes', value: '1'},
        {option: 'No', value: '0'}
      ]} optionText="option" optionValue="value"/>
      <div className="two-field-row">
        <CustomRichTextComponent source="X10ACNWLONGDESCR" label="Long description"/>
        <CustomRichTextComponent source="X10ACNWLONGDESCRENG" label="Long description English"/>
      </div>
      <DateInput label="Date From"
                 source="X10ACNWVISIBLEFROM"
                 name="X10ACNWVISIBLEFROM"
                 defaultValue={visibleFrom()}
                 validate={isRequired}/>
      <DateInput label="Date To"
                 source="X10ACNWVISIBLETO"
                 name="X10ACNWVISIBLETO"
                 defaultValue={visibleTo()}
                 validate={isRequired}/>
      <div className="two-field-row">
        <NumberInput source="X10ACNWORDER"
                     name="X10ACNWORDER"
                     label="Order*"/>
        <p style={{textAlign: 'center'}}>* 1 è la priorità massima</p>
      </div>
      <SelectInput name="X10ACNWEXTERNAL" source="X10ACNWEXTERNAL" defaultValue={false} label="Vis. Ext Users"
                   choices={[
                     {option: 'Yes', value: true},
                     {option: 'No', value: false}
                   ]} optionText="option" optionValue="value"/>
    </SimpleForm>
  </Create>
);

const CustomPositionSelector = () => {
  const {setValue} = useFormContext();
  return <ReferenceInput onChange={(ev) => {
    if (ev === POSITION.flash) {
      const prefilledDateTo = new Date();
      prefilledDateTo.setDate(prefilledDateTo.getDate() + 14);
      setValue('X10ACNWVISIBLETO', prefilledDateTo);
    }
  }}
                         source="X10ACNWID_X10LCNP"
                         name="X10ACNWID_X10LCNP"
                         reference="company-news-position">
    <AutocompleteInput label="Position"
                       name="X10ACNWID_X10LCNP"
                       optionText="X10LCNPDESCRIZIONE"/>
  </ReferenceInput>
}
