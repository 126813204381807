const TOTAL_COUNT_HEADER = "X-Total-Count";

const ADMIN_GROUP = 'Overme Admins'

const emptyRichText = [
  '<p><br></p>',
  '<h1><br></h1>',
  '<h2><br></h2>',
  '<h3><br></h3>',
];

export {emptyRichText, TOTAL_COUNT_HEADER, ADMIN_GROUP}
