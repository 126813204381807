import React from 'react';
import PropTypes from 'prop-types';

export const EllipsisText = ({source, record = {}, maxLength = 50, concatFields}) => {
  const text = concatFields
    ? concatFields.map(field => record[field]).join(' ')
    : record[source];

  const slicedText = text?.length >= maxLength
    ? `${text.slice(0, maxLength)}...`
    : text;

  return <span>{slicedText}</span>;
}

EllipsisText.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  charactersLength: PropTypes.number
};
