import React, {useEffect, useState} from "react";
import {SelectInput, useInput} from 'react-admin';
import {Config} from '../config/config';
import {getOptions} from "./util";

export const SimpleSelectField = (props) => {
  const {
    field,
  } = useInput(props);
  const {endpoint, params, perPage, sort, order, optionText, label, disabled, source, validate} = props;
  const [choices, setChoices] = useState([]);

  const fetchData = async () => {
    const response = await fetch(`${Config.serverUrl}/${endpoint}?_end=${perPage ?? ""}&_sort=${sort?.field ?? ""}&_start=0&_order=${order ?? ""}&${params ?? ""}`, getOptions({}));
    const result = await response.json();
    setChoices(result);
  }

  const handleSelectChange = ({target: {value}}) => {
    const newValue = isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);
    field.onChange(newValue);
  };

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return choices.length ? (
    <SelectInput source={source}
                 onChange={handleSelectChange}
                 name={source}
                 label={label}
                 disabled={disabled}
                 validate={validate}
                 choices={choices.map(item => ({option: item[optionText], value: item.id}))}
                 optionText="option"
                 optionValue="value"/>
  ) : null;
};
